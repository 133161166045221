import React from 'react';
import styled from 'styled-components';

const PageHeaderSection = styled.section`
	background-color: #1f0e49;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='46' height='46' fill-opacity='0.47' fill='%2326115a'/%3E%3C/svg%3E");
	/* background by SVGBackgrounds.com */
	padding-top: 50px;
	padding-bottom: 50px;

	h1 {
		font-size: 84px;
		color: white;
		text-align: center;

		@media screen and (max-width: 767px) {
			font-size: 62px;
			text-align: left;
		}
	}

	span {
		color: #fdf4b4;
		font-family: PT Serif;
	}
`;

const PageHeader = (props) => {
	return (
		<PageHeaderSection>
			<div className="container">
				<h1>
					{props.title}
					<span>.</span>
				</h1>
			</div>
		</PageHeaderSection>
	);
};

export default PageHeader;
