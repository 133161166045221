import React from 'react';
import styled from 'styled-components';

const StyledProject = styled.article`
	h3 {
		font-size: 30px;
	}

	img {
		transition: all .5s ease;
	}

	a {
		text-decoration: none;
	}

	.excerpt {
		color: rgb(105, 105, 105);
	}
`;

const Project = (props) => {
	return (
		<StyledProject>
			<a target="_blank" rel="noreferrer noopener nofollow" href={`${props.to}`}>
				<figure className="mb-3">{props.image}</figure>
				<h3 className="mb-2">{props.title}</h3>
				<p className="mb-3 excerpt">{props.children}</p>
				<p>view project ›</p>
			</a>
		</StyledProject>
	);
};

export default Project;
