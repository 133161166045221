import React from 'react';
import Layout from '../components/global/Layout';
import PageHeader from '../components/global/PageHeader';
import Project from '../components/global/Project';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import { graphql } from 'gatsby';

const StyledGrid = styled.div`
	display: grid;
	grid-column-gap: 100px;
	grid-row-gap: 50px;
	grid-template-columns: 1fr 1fr;

	@media screen and (max-width: 767px) {
		grid-template-columns: 1fr;
		grid-column-gap: 50px;
		grid-row-gap: 50px;
	}
`;

export const query = graphql`
	query {
		contrasty: file(relativePath: { eq: "contrasty.png" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		mpg: file(relativePath: { eq: "mpg.png" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		journey: file(relativePath: { eq: "journey-price-calculator.png" }) {
			childImageSharp {
				fluid(quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;

const ProjectsPage = ({ data }) => {
	return (
		<Layout>
			<PageHeader title="Projects" />
			<main>
				<section className="section-padding">
					<div className="container">
						<div className="row">
							<div className="col-lg-8">
								<h2 className="sub-heading">what i've been working on</h2>
								<p>
									The following are a selection of personal projects I have worked on. These do not
									include any of the projects I complete at work.
								</p>
							</div>
						</div>
					</div>
				</section>
				<section>
					<div className="container">
						<StyledGrid className="mb-50">
							
							<Project
								image={<Img fluid={data.mpg.childImageSharp.fluid} alt="" />}
								title="MPG Calculator"
								to="https://www.mpg-calculator.co.uk/"
							>
								A NEXT.js site which allows users to calculate the miles per gallon of their vehicle. Also uses TypeScript, GraphQL and Node.js.
							</Project>
							<Project
								image={<Img fluid={data.journey.childImageSharp.fluid} alt="" />}
								title="Journey Price Calculator"
								to="https://www.mpg-calculator.co.uk/journey-price/"
							>
								A site which allows users to calculate the cost of a journey based on the distance, mpg
								and fuel price.
							</Project>
							<Project
								image={<Img fluid={data.contrasty.childImageSharp.fluid} alt="" />}
								title="Contrasty"
								to="https://contrasty.netlify.com/"
							>
								A React WCAG contrast checker for web developers and designers to ensure that their
								applications pass the minimum requirements for accessibility.
							</Project>
						</StyledGrid>
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default ProjectsPage;
